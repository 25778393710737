<template>
  <v-card :color="color" :loading="loading">
    <v-list-item three-line>
      <v-list-item-content>
        <v-list-item-subtitle style="color: rgb(255 233 119);font-weight: 300;font-size: 12px;">{{subTitle}}</v-list-item-subtitle>

        <v-list-item-title class="mb-0" style="color:#fff;font-size:16px;font-weight:600;text-transform: uppercase;">
          {{title}}
        </v-list-item-title>
        <div class="mt-3" style="color: rgb(255 255 255);font-size: 13px;font-weight: 500;">
           <slot name="content"></slot>
        </div>
      </v-list-item-content>

      <!-- <v-list-item-avatar tile size="50">
        <v-icon color="secondary" x-large>{{icon}}</v-icon>
      </v-list-item-avatar> -->
    </v-list-item>
  </v-card>
</template>

<script>
  export default {
    data: () => ({
      loading: false,
    }),
    props: ['color','icon', 'title','subTitle','val'],
  }
</script>